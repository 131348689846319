import { render, staticRenderFns } from "./_tower.vue?vue&type=template&id=6152e91a&scoped=true&lang=pug&"
import script from "./_tower.vue?vue&type=script&lang=coffee&"
export * from "./_tower.vue?vue&type=script&lang=coffee&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6152e91a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BlocksList: require('/opt/build/repo/nuxt-app/components/globals/blocks/list.vue').default,BlocksNewsletter: require('/opt/build/repo/nuxt-app/components/blocks/newsletter.vue').default})
