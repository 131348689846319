












import getTower from '~/queries/tower.gql'
import pageMixin from '@cloak-app/craft/mixins/page'

export default
	name: 'Tower'

	mixins: [ pageMixin ]

	# Get Tower data
	asyncData: ({ $craft, $notFound, route }) ->

		# Get data
		page = await $craft.getEntry
			query: getTower
			variables: uri: route.path?.substr(1) || '__home__'
		return $notFound() unless page

		# Throw an error if no blocks are found to stop builds with empty pages
		unless page.blocks?.length
		then throw "Page missing blocks"

		# Set data
		return { page }

	mounted: -> @$tealiumEcomm.pageView @page

